<template>
  <section class="page-container">
    <div class="flex-col header text-left fw-500  color-dark">
      <span class="lh-150 fs-26">Racing Max</span>
      <span class="lh-150 fs-42 ">鴻朗保險</span>
    </div>
    <div class="flex-sb-center content">
      <div class="text-left flex-col">
        <img
          class="img__confrim"
          src="../../assets/images/index/success.png"
          alt="">
        <div class="flex-col color-dark">
          <span class="fs-22 lh-150">Policy may need to be referred, please ask the counter staff for help
            application to be processed.</span>
          <span class="fs-32 lh-150">保單或需轉介，請到櫃台處理投保</span>
        </div>
        <span class="mg-t-32">
          <v-button @click="handleStart">確定</v-button>
        </span>
      </div>

    </div>
  </section>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'unconfrim',
  computed:{
    ...mapState('quotation',['quotationData']),
    serial_number(){
      let {serial_number} = this.quotationData||{}
      return serial_number||0
    }
  },
  methods:{
    ...mapActions('quotation',['setQuoteInformationForm','setQuotationData']),
    handleStart(){
      this.setQuoteInformationForm(null)
      this.setQuotationData(null)
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped lang="scss">
.page-container{
  height: 100vh;
  position: relative;
  overflow: auto;
  .header{
    padding:38px 0 0 32px;
  }
  .content{
    padding: 0px 48px 39px 32px;
    transform: translateY(25%);
    .img__confrim{
      width: 169px;
      height: 169px;
      margin-bottom: 8px;
    }
    @media screen and (max-width: 768px) {
      transform: translateY(25%);
    }
  }
}
</style>
